exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-templates-post-template-tsx": () => import("./../../../src/components/templates/PostTemplate.tsx" /* webpackChunkName: "component---src-components-templates-post-template-tsx" */),
  "component---src-pages-404-ts": () => import("./../../../src/pages/404.ts" /* webpackChunkName: "component---src-pages-404-ts" */),
  "component---src-pages-en-about-ts": () => import("./../../../src/pages/en/about.ts" /* webpackChunkName: "component---src-pages-en-about-ts" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-en-projects-ts": () => import("./../../../src/pages/en/projects.ts" /* webpackChunkName: "component---src-pages-en-projects-ts" */),
  "component---src-pages-en-publications-ts": () => import("./../../../src/pages/en/publications.ts" /* webpackChunkName: "component---src-pages-en-publications-ts" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ko-about-ts": () => import("./../../../src/pages/ko/about.ts" /* webpackChunkName: "component---src-pages-ko-about-ts" */),
  "component---src-pages-ko-index-tsx": () => import("./../../../src/pages/ko/index.tsx" /* webpackChunkName: "component---src-pages-ko-index-tsx" */),
  "component---src-pages-ko-posts-ts": () => import("./../../../src/pages/ko/posts.ts" /* webpackChunkName: "component---src-pages-ko-posts-ts" */),
  "component---src-pages-ko-projects-ts": () => import("./../../../src/pages/ko/projects.ts" /* webpackChunkName: "component---src-pages-ko-projects-ts" */),
  "component---src-pages-ko-publications-ts": () => import("./../../../src/pages/ko/publications.ts" /* webpackChunkName: "component---src-pages-ko-publications-ts" */)
}

